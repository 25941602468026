<template>
  <div class="user-programs--revenue-table">
    <v-simple-table>
      <thead>
        <tr>
          <th>Program/Challenge Name</th>
          <th class="text-right">IAP Count</th>
          <th class="text-right">IAP Revenue</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="hasPrograms && !loading">
          <tr v-for="program in programs" :key="program.id">
            <td style="width: 30%">
              <h2 class="subtitle-1">{{ program.name }}</h2>
            </td>
            <td class="text-right">{{ program.count || 0 }}</td>
            <td class="text-right">{{ program.amount || 0 }}</td>
          </tr>
          <tr>
            <td class="text-right">Total</td>
            <td class="text-right">{{ total.count }}</td>
            <td class="text-right">{{ total.revenue }}</td>
          </tr>
        </template>
      </tbody>
      <table-skeleton-loader v-if="loading" column="5" row />
    </v-simple-table>

    <no-list v-if="!hasPrograms && !loading" details="No Programs Enrolled" />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'

export default {
  name: 'ProgramRevenueTable',

  components: {
    NoList,
    TableSkeletonLoader,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    programs: {
      type: Array,
      default: () => [],
      required: true,
    },

    total: {
      type: Object,
      default: () => {
        return {
          count: 0,
          revenue: 0,
        }
      },
      required: true,
    },
  },

  computed: {
    hasPrograms() {
      return !!this.programs.length
    },
  },

  methods: {},
}
</script>
