<template>
  <div class="user-profile--programs-list">
    <v-row>
      <v-col cols="12" sm="12" md="10">
        <program-revenue-table
          :programs="programs"
          :total="total"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProgramRevenueTable from '../components/ProgramRevenueTable'

import { mdiPlus } from '@mdi/js'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'UserProgramRevenueList',

  components: {
    ProgramRevenueTable,
  },

  data() {
    return {
      loading: true,
      icons: {
        add: mdiPlus,
      },
    }
  },

  created() {
    this.fetchPrograms()
  },

  destroyed() {
    this.clearPrograms()
  },

  computed: {
    ...mapState({
      programs: (state) => state.userProfile.programRevenues,
      total: (state) => state.userProfile.programRevenueTotal,
    }),
  },

  methods: {
    ...mapActions({ getPrograms: 'userProfile/getProgramRevenue' }),
    ...mapMutations({ clearPrograms: 'userProfile/clearPrograms' }),

    async fetchPrograms() {
      await this.getPrograms(this.$route.params.id)

      await this.$sleep()

      this.loading = false
    },
  },
}
</script>
